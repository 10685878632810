import CloseIcon from '@material-ui/icons/Close';
import MUIAutocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import CheckBox from '../CheckBox';
import TextField from '../TextField';
import { useTrans } from '../../../../services/i18n';

const ChipProps = { deleteIcon: <CloseIcon /> };

const getOptionSelected = (option, value) => {
  if (option && option.id) return option.id === (value && value.id);
  return option === value;
};

const getOptionLabelFormatted = (labelKey, formatLabelCB, option, trans) => {
  return labelKey ? (option?.[labelKey] ? formatLabelCB(option?.[labelKey], trans) : '') : option;
};

const getOptionLabel = (labelKey, option) => {
  return labelKey ? option[labelKey] || '' : option;
};

const CheckedOption = ({ label, selected }) => (
  <React.Fragment>
    <CheckBox checked={selected} />
    {label}
  </React.Fragment>
);

export default function Autocomplete({
  label,
  options,
  autoFocus,
  error,
  required,
  onChange,
  value,
  labelKey = 'name',
  name = 'auto',
  disableCloseOnSelect = false,
  hideCheckboxForMultiple = false,
  multiple = false,
  placeholder = 'Select to enter...',
  plain = false,
  formatLabelCB = null,
  maxTagTextLength = null,
  validateOnEnterPress = true,
  onInputChange,
  ...rest
}) {
  const { trans } = useTrans();
  const textFieldProps = { name, label, autoFocus, error, required, placeholder };

  const handleSelectAll = () => {
    if (options && options.length > 0) {
      if (multiple) {
        const currentSelection = Array.isArray(value) ? value : [];
        const unionSelection = [...currentSelection];
        options.forEach((option) => {
          const alreadySelected = currentSelection.some((selectedOption) => getOptionSelected(option, selectedOption));
          if (!alreadySelected) {
            unionSelection.push(option);
          }
        });
        if (typeof onChange === 'function') {
          onChange({ target: { name, value: unionSelection } });
        }
      } else {
        if (typeof onChange === 'function') {
          onChange({ target: { name, value: options[0] } });
        }
      }
      setInputValue('');
    }
  };

  const ListboxWithSelectAll = React.forwardRef(function ListboxWithSelectAll(props, ref) {
    const { children, ...other } = props;
    return (
      <div ref={ref} {...other}>
        <button
          type="button"
          onClick={handleSelectAll}
          style={{
            width: '100%',
            padding: '8px',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            textAlign: 'left',
            color: 'blue',
            marginLeft: '8px',
          }}
        >
          Select All
        </button>
        {children}
      </div>
    );
  });

  const [inputValue, setInputValue] = React.useState('');

  const handleChange = React.useCallback(
    (_event, value) => {
      try {
        if (typeof onChange === 'function') return onChange({ target: { name, value } });
      } catch (error) {
        return;
      }
    },
    [name, onChange]
  );

  const manageFormatLabelCB = (option) => {
    const formattedLabel = getOptionLabelFormatted(labelKey, formatLabelCB, option, trans);
    return formattedLabel;
  };

  const handleCombinedInputChange = (event, newInputValue, reason) => {
    if (reason === 'reset') return; // prevent clearing on selection
    setInputValue(newInputValue);
    if (typeof onInputChange === 'function') {
      onInputChange(event, newInputValue, reason);
    }
  };

  const keyPress = React.useCallback(
    (e) => {
      if (e.keyCode === 13 && e.target.value) {
        e.stopPropagation();
        if (validateOnEnterPress) {
          const val = e.target.value.toLowerCase();
          const find = options.find((option) => option.id.toLowerCase() === val || option.value.toLowerCase() === val);
          if (find) {
            const exists = value.find((item) => item.id === find.id);
            if (!exists) {
              const newValue = [...value, find];
              if (typeof onChange === 'function') return onChange({ target: { name, value: newValue } });
            }
          }
        } else {
          const newValue = [...value, { create: e.target.value, value: e.target.value }];
          if (typeof onChange === 'function') return onChange({ target: { name, value: newValue } });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, onChange, options, value]
  );

  return (
    <>
      <MUIAutocomplete
        id={`autocomplete-${name}`}
        multiple={multiple}
        value={value}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleCombinedInputChange}
        options={options || []}
        getOptionLabel={
          typeof formatLabelCB === 'function' ? manageFormatLabelCB.bind(null) : getOptionLabel.bind(null, labelKey)
        }
        getOptionSelected={getOptionSelected}
        ListboxComponent={ListboxWithSelectAll}
        renderInput={(params) => (
          <TextField
            className={label ? 'aclabel' : 'achiddenlabel'}
            {...params}
            {...textFieldProps}
            onKeyDown={keyPress}
            maxLength={maxTagTextLength}
          />
        )}
        ChipProps={ChipProps}
        disableCloseOnSelect={disableCloseOnSelect}
        renderOption={
          disableCloseOnSelect && !hideCheckboxForMultiple
            ? (option, { selected }) => <CheckedOption label={plain ? option : option[labelKey]} selected={selected} />
            : undefined
        }
        {...rest}
      />
    </>
  );
}
