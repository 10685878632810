import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import MUITypography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  customPopper: {
    zIndex: 9999,
  },
  customTooltip: {
    fontSize: '0.9rem',
    maxWidth: 300,
    marginLeft: '70px',

    backgroundColor: 'lightblue',
    color: 'black',
  },
  customArrow: {
    color: 'lightblue',
  },
}));

const HoverTypography = React.forwardRef(function HoverTypography({ hoverText, style, ...props }, ref) {
  const classes = useStyles();

  if (hoverText) {
    return (
      <Tooltip
        title={hoverText}
        arrow
        placement="top"
        PopperProps={{
          disablePortal: false,
          modifiers: {
            offset: {
              enabled: true,
              offset: '50, 0',
            },
          },
        }}
        classes={{ popper: classes.customPopper, tooltip: classes.customTooltip }}
      >
        <span style={{ display: 'inline-block', pointerEvents: 'auto', ...style }}>
          <MUITypography ref={ref} {...props} />
        </span>
      </Tooltip>
    );
  }
  return <MUITypography ref={ref} style={style} {...props} />;
});

export default HoverTypography;
