import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useSelector } from 'react-redux';
import { RouterLink } from '../../../components/common/Buttons';
import Typography from '../../../components/common/Typography';
import FieldValue from '../../../components/generics/Helpers/FieldValue';
import { DATE_FORMAT, ROUTE_CODE_CATEGORIES_DETAILS } from '../../../constants';
import { useTrans } from '../../../services/i18n';
import { formatPrettyDate } from '../../../utils/dateUtils';
import { makeStyles } from '@material-ui/core';

const teamingFieldStyles = {
  weight: '400',
  weightLabel: '500',
  fontFamily: 'Manrope',
  variant: 'body2',
};

const useStyles = makeStyles((theme) => ({
  teamingTypography: {
    color: theme.palette.common,
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  ulStyle: {
    paddingInlineStart: '1.374rem',
    marginTop: '0.063rem',
  },
}));

export default function VendorItemManagementDetailFields({ item }) {
  const { trans } = useTrans();
  const asideList = useSelector((state) => state.vendorsManagementDetails.asideList);
  const styles = useStyles();

  return !item ? null : (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FieldValue
          value={item.bussinessStartDate ? formatPrettyDate(item.bussinessStartDate, DATE_FORMAT) : ''}
          label={trans('BusinessStartDate')}
          {...teamingFieldStyles}
        />
      </Grid>

      <Grid item xs={6}>
        <FieldValue
          value={item.expirationDate ? formatPrettyDate(item.expirationDate, DATE_FORMAT) : ''}
          label={trans('expiration-date')}
          {...teamingFieldStyles}
        />
      </Grid>

      <Grid item xs={6}>
        <FieldValue value={item.ueiCode} label={trans('uei-code')} {...teamingFieldStyles} />
      </Grid>

      <Grid item xs={6}>
        <FieldValue value={item.cageCode} label={trans('CAGECode')} {...teamingFieldStyles} />
      </Grid>

      <Grid item sm={6} xs={12}>
        {item && !!item?.setAsides?.length ? (
          <FieldValue label={trans('SetAsides')} {...teamingFieldStyles}>
            <ul className={styles.ulStyle}>
              {/* {item.setAsides.map((sa, index) => (
                <li>
                  <Typography variant="h6" component="p" key={index} weight="500" className={styles.teamingTypography}>
                    {sa} - {asideList.find((s) => s.id === sa)?.value}
                  </Typography>
                </li>
              ))} */}
              {/* old code above, keeping just in case we have to go back */}
              {item.setAsides.map((sa, index) => {
                // Check if the setAside is an object; if so, extract its code and description
                const isObject = typeof sa === 'object' && sa !== null;
                const asideDisplay = isObject ? `${sa.code} - ${sa.description}` : sa;
                const asideId = isObject ? sa.code : sa;
                const asideExtra = asideList.find((s) => s.id === asideId)?.value;
                return (
                  <li key={asideId || index}>
                    <Typography variant="h6" component="p" weight="500" className={styles.teamingTypography}>
                      {asideDisplay} {asideExtra ? `- ${asideExtra}` : ''}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </FieldValue>
        ) : (
          <FieldValue label={trans('SetAsides')} value="" {...teamingFieldStyles} />
        )}
      </Grid>

      <Grid item xs={12}>
        {item && !!item?.naics?.length ? (
          <FieldValue label={trans('NAICS-codes')} {...teamingFieldStyles}>
            {item.naics?.map((i) => (
              <Typography component="p" key={i}>
                <Typography
                  variant="h6"
                  weight="500"
                  component={i.name ? RouterLink : ''}
                  to={ROUTE_CODE_CATEGORIES_DETAILS.path.replace(':id', `NAICS-${i.code}`)}
                >
                  {i.code === item.primaryNaics ? 'Primary - ' : ''} {i.code} - {i.name}
                </Typography>
              </Typography>
            ))}
          </FieldValue>
        ) : (
          <FieldValue label={trans('NAICS-codes')} value="" {...teamingFieldStyles} />
        )}
      </Grid>
    </Grid>
  );
}
