export const pipelineStagesHoverMapping = {
  'SOURCE SOUGHT':
    'A Sources Sought Notice is a government request to identify potential vendors or contractors capable of fulfilling a specific project or service requirement.',
  'PRE-SOLICITATION':
    'A Presolicitation Notice is an early announcement from a government agency about an upcoming contract opportunity to inform potential vendors before the official solicitation is released.',
  'PRE-BID':
    'Preparing for a bid involves reviewing the project requirements, assessing your capabilities, gathering necessary documentation, and developing a competitive, compliant proposal.',
  'BID SUBMITTED':
    'Submitting a bid involves finalizing your proposal, ensuring all required documents are included, following submission instructions carefully, and submitting the bid before the deadline.',
  WON: 'Winning a bid means your proposal is selected as the best option, making you the chosen contractor or vendor for the project.',
  LOST: 'Losing a bid means your proposal was not selected, often due to factors like a higher price, lower technical quality, or better offers from competing vendors.',
  ABANDONED:
    'A bid abandoned by the contracting officer means the procurement process is canceled or terminated due to issues like funding, changing requirements, or unforeseen circumstances.',
  TRIAGE:
    'In a pipeline, triage means prioritizing tasks or issues based on their urgency and importance to ensure critical ones are addressed first.',
};
