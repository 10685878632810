import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import { ANY_OF_THOSE, NONE_OF_THOSE } from '../../constants/filter';
import { application } from '../../services/application';
import { omit } from '../../utils/helpers';
import { onItemDetails, onPending } from '../helpers/sharedCases';
import { API } from '../../constants';

const MORE_OPPORTUNITIES = {
  location: null,
  pagination: {
    page: 0,
    perPage: 3,
  },
  count: 0,
  items: [],
};

export const INITIAL_STATE = {
  item: {},
  pending: false,
  moreOpportunities: MORE_OPPORTUNITIES,
  history: {
    items: [],
    index: 0,
    current: {},
    nextIndex: 0,
    prevIndex: 0,
  },
};

export const fetchMoreOpportunities = createAsyncThunk(
  'contractOpportunities/fetchMoreContractOpportunities',
  async (_, { getState }) => {
    const { contractOpportunityDetails } = getState();
    const { item } = contractOpportunityDetails;
    const { page, perPage } = contractOpportunityDetails.moreOpportunities.pagination;

    const { abv, state, city } = item?.placeOfPerformance;
    if (!abv || !state || !city) return MORE_OPPORTUNITIES;
    console.log('fetchMoreOpportunities', item);
    const params = {
      filters: {
        state: { value: [{ id: abv }, { id: state }], type: ANY_OF_THOSE },
        city: { value: [{ id: city }], type: ANY_OF_THOSE },
        id: { value: [{ id: item.id }], type: NONE_OF_THOSE },
      },
      pagination: {
        page,
        perPage,
      },
    };
    const data = await application.call('contractOpportunities.getList', params);
    return {
      ...data,
      pagination: {
        page: page + 1,
        perPage,
      },
    };
  }
);

export const fetchOpportunity = createAsyncThunk(
  'contractOpportunities/fetchOpportunity',
  async ({ id, searchText }) => {
    return application.call('contractOpportunities.findOne', { id, searchText });
  }
);

export const fetchHistory = createAsyncThunk('contractOpportunities/fetchHistory', async (id) => {
  const items = await application.call('contractOpportunities.getHistory', { id }).then((res) => res.reverse());
  const index = items.indexOf(items.find((i) => i.opportunityId === id));
  const current = items[index];
  return {
    items,
    index,
    current,
    nextIndex: items[index + 1] ? index + 1 : null,
    prevIndex: items[index - 1] ? index - 1 : null,
  };
});

export const changeHistory = createAsyncThunk('contractOpportunities/changeHistory', async (index, { getState }) => {
  const { contractOpportunityDetails } = getState();
  const { history } = contractOpportunityDetails;
  const historyObj = history.items[index];

  const newItem = await application.call('contractOpportunities.findOne', { id: historyObj.opportunityId });
  const item = { ...contractOpportunityDetails.item, ...newItem };

  return {
    item,
    index,
    current: historyObj,
    nextIndex: history.items[index + 1] ? index + 1 : null,
    prevIndex: history.items[index - 1] ? index - 1 : null,
  };
  // const newItem =
});

export const setMoreOpportunities = createAction('contractOpportunities/setMoreOpportunities');

export const cleanStore = createAction('contractOpportunities/cleanStore');

export const addToFavorite = createAsyncThunk('contractOpportunities/toggleFavorite', (params) => {
  return application.call(API.CONTRACT_OPPORTUNITIES.TOGGLE_FAVORITE, params);
});

export const actions = {
  fetchOpportunity,
  fetchHistory,
  changeHistory,
  fetchMoreOpportunities,
  setMoreOpportunities,
  cleanStore,
  addToFavorite,
};

function onFetchMoreOpportunities(state, action) {
  return Object.assign(state, {
    moreOpportunities: Object.assign(state.moreOpportunities, {
      items: [].concat(state.moreOpportunities.items, action.payload.items),
      count: action.payload.count,
      pagination: action.payload.pagination,
      pending: false,
    }),
  });
}

function onPendingMoreOpportunities(state) {
  return Object.assign(state, { moreOpportunities: Object.assign({}, state.moreOpportunities, { pending: true }) });
}

function onSetMoreOpportunities(state, action) {
  return Object.assign(state, { moreOpportunities: action.payload });
}

function onPendingHistory(state) {
  return Object.assign(state, { history: Object.assign({}, state.history) }, { pending: true });
}

function onFetchHistory(state, action) {
  return Object.assign(state, { history: Object.assign({}, state.history, action.payload) }, { pending: false });
}

function onChangeHistory(state, action) {
  return Object.assign(
    state,
    { history: Object.assign({}, state.history, omit(action.payload, 'item')) },
    { item: action.payload.item, pending: false }
  );
}

function onChangeHistoryPending(state) {
  return Object.assign(state, { history: Object.assign({}, state.history) }, { pending: true });
}

function onAddToFavorite(state, action) {
  if (state.item && state.item.id === action.payload.id) {
    state.item = {
      ...state.item,
      isPotentialPartner: action.payload.isPotentialPartner,
    };
  }
  state.pending = false;
  return state;
}

export default createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(fetchOpportunity.fulfilled, onItemDetails)
    .addCase(fetchOpportunity.pending, onPending)
    .addCase(fetchMoreOpportunities.fulfilled, onFetchMoreOpportunities)
    .addCase(setMoreOpportunities, onSetMoreOpportunities)
    .addCase(fetchMoreOpportunities.pending, onPendingMoreOpportunities)
    .addCase(fetchHistory.pending, onPendingHistory)
    .addCase(fetchHistory.fulfilled, onFetchHistory)
    .addCase(changeHistory.fulfilled, onChangeHistory)
    .addCase(changeHistory.pending, onChangeHistoryPending)
    .addCase(cleanStore, () => INITIAL_STATE)
    // New favorite cases
    .addCase(addToFavorite.fulfilled, onAddToFavorite)
    .addCase(addToFavorite.pending, onPending);
});
