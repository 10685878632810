import { createAsyncThunk, createReducer, createAction } from '@reduxjs/toolkit';
import { API, SCOPE_PIPELINES } from '../../constants';
import { application } from '../../services/application';
import { extendListBuilder, generateListActions, INITIAL_LIST_STATE_EXTENDED } from '../helpers/listActionsHelpers';
import { onPending, onPendingDone } from '../helpers/sharedCases';

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['name', 'asc'],
  userPipelines: [],
};

//Actions
const addPipeline = createAsyncThunk('pipelines/storePipeline', (params) => {
  return application.call(API.PIPELINES.ADD_PIPELINE, params);
});

const addPursuitToPipeline = createAsyncThunk('pipelines/addPursuitToPipeline', (params) => {
  return application.call(API.PIPELINES.ADD_PURSUIT, params);
});

const getPipelines = createAsyncThunk('pipelines/getPipelines', (params) => {
  return application.call(API.PIPELINES.GET_LIST, params);
});

const getPursuitById = createAsyncThunk('pursuits/findOne', (params) => {
  return application.call(API.PURSUITS.GET_PURSUIT_BY_ID, params);
});

const getTasksByPursuitId = createAsyncThunk('pursuits/getTasksByPursuitId', (params) => {
  return application.call(API.PURSUITS.GET_TASKS_BY_PURSUIT_ID, params);
});

const onSaveTask = createAsyncThunk('pursuits/onSaveTask', (params) => {
  return application.call(API.PURSUITS.STORE_TASK_ON_PURSUIT, params);
});

const onEditTask = createAsyncThunk('pursuits/onEditTask', (params) => {
  return application.call(API.PURSUITS.UPDATE_TASK_ON_PURSUIT, params);
});

const deleteTaskById = createAsyncThunk('pipelines/deleteTaskById', (params) => {
  return application.call(API.PURSUITS.DELETE_TASK_BY_ID, params);
});

const toggleIncludeInStatistics = createAsyncThunk('pipelines/toggleIncludeInStatistics', (params) => {
  return application.call(API.PIPELINES.TOGGLE_INCLUDE_IN_STATISTICS, params);
});

const getTagsByUser = createAsyncThunk('tagsByUser/getTagsByUser', (params) => {
  if (params.filter && params.filter.text) {
    return application.call(API.TAGS_USER.GET_TAGS_BY_USER, {
      text: params.filter.text,
      type: 'pipelines',
    });
  }
});
const changeStatusTaskById = createAsyncThunk('pipelines/changeStatusTaskById', (params) => {
  return application.call(API.PURSUITS.CHANGE_STATUS_TASK_BY_ID, params);
});

const removePipelineList = createAction('pipelines/removePipelineList');

const listActions = generateListActions({
  scope: SCOPE_PIPELINES,
  apiMethod: {
    GET_LIST: API.PIPELINES.GET_LIST,
  },
  getStore: (store) => store.pipelines,
});

//Reducers
function onFetchList(state, action) {
  Object.assign(state, {
    items: [action.payload, ...state.items],
    pending: false,
    count: state.count + 1,
  });
}
// old function, not sure if we will need later

// function onUserPipelines(state, action) {
//   Object.assign(state, {
//     userPipelines: action.payload ? [...action.payload.items] : [],
//   });
// }

function onUserPipelines(state, action) {
  const items = action.payload ? [...action.payload.items] : [];
  Object.assign(state, {
    items,
    count: action.payload ? action.payload.count : items.length,
    userPipelines: items,
    pending: false,
  });
}

function onAddPursuitToPipeline(state, action) {}

export const actions = {
  ...listActions,
  addPipeline,
  getPipelines,
  addPursuitToPipeline,
  getPursuitById,
  getTasksByPursuitId,
  onSaveTask,
  onEditTask,
  deleteTaskById,
  getTagsByUser,
  changeStatusTaskById,
  removePipelineList,
  toggleIncludeInStatistics,
};

export default createReducer(INITIAL_STATE, (builder) => {
  return (
    extendListBuilder(builder, {
      ...listActions,
    })
      .addCase(getPursuitById.pending, onPending)
      .addCase(getPursuitById.fulfilled, (state, action) => {
        state.pending = false;
      })
      .addCase(addPipeline.pending, onPending)
      .addCase(addPipeline.rejected, onPendingDone)
      .addCase(addPipeline.fulfilled, onFetchList)
      .addCase(getPipelines.fulfilled, onUserPipelines)
      .addCase(addPursuitToPipeline.fulfilled, onAddPursuitToPipeline)
      .addCase(getTasksByPursuitId.fulfilled, () => {})
      //.addCase(onSaveTask.pending, onPending)
      .addCase(onSaveTask.rejected, onPendingDone)
      .addCase(onSaveTask.fulfilled, () => {})
      .addCase(onEditTask.pending, onPending)
      .addCase(onEditTask.rejected, onPendingDone)
      .addCase(onEditTask.fulfilled, () => {})
      .addCase(deleteTaskById.pending, () => {})
      .addCase(deleteTaskById.rejected, () => {})
      .addCase(deleteTaskById.fulfilled, () => {})
      .addCase(changeStatusTaskById.fulfilled, () => {})
      .addCase(removePipelineList, (state, action) => {
        const i = state.items.findIndex((i) => i.id === action.payload.id);
        if (i !== -1) {
          state.items.splice(i, 1);
          state.count = state.count - 1;
        }
      })
  );
});
