import React from 'react';
import { SvgIcon } from '../../../common/SvgIcon';
import { simpleIconPropsTypes } from '../../propTypes';

export const IconStar = (props) => {
  const { width = 22, height = 22 } = props;
  return (
    <SvgIcon width={width} height={height} fill="none" viewBox="0 0 22 22">
      <path
        d="M8.15316 4.40838C9.41981 2.13613 10.0531 1 11 1C11.9469 1 12.5802 2.13612 13.8468 4.40837L14.1745 4.99623C14.5345 5.64193 14.7144 5.96479 14.9951 6.17781C15.2757 6.39083 15.6251 6.4699 16.3241 6.62805L16.9605 6.77203C19.4201 7.32856 20.65 7.60682 20.9426 8.54773C21.2352 9.48864 20.3968 10.4691 18.7199 12.4299L18.2861 12.9372C17.8096 13.4944 17.5713 13.773 17.4641 14.1177C17.357 14.4624 17.393 14.8341 17.465 15.5776L17.5306 16.2544C17.7841 18.8706 17.9109 20.1787 17.1449 20.7602C16.3788 21.3417 15.2273 20.8115 12.9243 19.7512L12.3285 19.4768C11.6741 19.1755 11.3469 19.0248 11 19.0248C10.6531 19.0248 10.3259 19.1755 9.67149 19.4768L9.07567 19.7512C6.77268 20.8115 5.62118 21.3417 4.85515 20.7602C4.08912 20.1787 4.21588 18.8706 4.4694 16.2544L4.53498 15.5776C4.60703 14.8341 4.64305 14.4624 4.53586 14.1177C4.42868 13.773 4.19043 13.4944 3.71392 12.9372L3.2801 12.4299C1.60325 10.4691 0.764825 9.48864 1.05742 8.54773C1.35002 7.60682 2.57986 7.32856 5.03954 6.77203L5.67589 6.62805C6.37485 6.4699 6.72433 6.39083 7.00494 6.17781C7.28555 5.96479 7.46553 5.64194 7.82547 4.99623L8.15316 4.40838Z"
        strokeWidth="2"
      />
    </SvgIcon>
  );
};

IconStar.propTypes = simpleIconPropsTypes;
