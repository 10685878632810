import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Suspense from '../../services/lazyLoad/Suspense';
import { AddToPipelineProvider } from '../../contexts/AddToPipelineContext';
import { ROUTE_MY_FAVORITES } from '../../constants';

const MyFavorites = React.lazy(() => import('./MyFavorites'));

export default function MyFavoritesWrapper() {
  return (
    <Suspense>
      <AddToPipelineProvider scope="FAVORITES">
        <Switch>
          <Route exact path={ROUTE_MY_FAVORITES.path}>
            <MyFavorites />
          </Route>
          <Redirect exact path="/*" to={ROUTE_MY_FAVORITES.path} />
        </Switch>
      </AddToPipelineProvider>
    </Suspense>
  );
}
