export const EZ_SEARCH = {
  GET_LIST: 'ezSearch.search',
  GET_SAVED_SEARCHS: 'ezSearch.getSavedSearchs',
};

export const SAVED_SEARCH = {
  GET_LIST: 'savedSearch.getList',
  CREATE: 'savedSearch.save',
  MODIFY: 'savedSearch.modify',
  DELETE: 'savedSearch.delete',
  FIND_ONE: 'savedSearch.findOne',
  SHARE_WITH_EMAIL: 'savedSearch.shareWithEmail',
  TOGGLE_WATCH: 'savedSearch.toggleWatch',
  DELETE_BULK: 'savedSearch.deleteBulk',
  TOGGLE_WATCH_BULK: 'savedSearch.toggleWatchBulk',
  ADD_TO_ORG_SAVED_SEARCHES: 'savedSearch.addToOrganizationSavedSearches',
  GET_STATS: 'savedSearch.getStats',
};

export const ORGANIZATION_SAVED_SEARCH = {
  GET_LIST: 'organizationSavedSearch.getList',
  CREATE: 'organizationSavedSearch.save',
  MODIFY: 'organizationSavedSearch.modify',
  DELETE: 'organizationSavedSearch.delete',
  FIND_ONE: 'organizationSavedSearch.findOne',
  SHARE_WITH_EMAIL: 'organizationSavedSearch.shareWithEmail',
  ADD_TO_MY_SAVED_SEARCHES: 'organizationSavedSearch.addToMySavedSearches',
};

export const SHARED_SEARCH = {
  CREATE: 'sharedSearch.createShare',
  FIND_ONE: 'sharedSearch.findOne',
  SHARE_WITH_EMAIL: 'sharedSearch.shareWithEmail',
};

export const GRANT_OPPORTUNITIES = {
  EXPORT_CSV: 'grantOpportunities.exportCSV',
  ADD_NOT_INTERESTED: 'listOfNotInterested.create',
  GET_LIST: 'grantOpportunities.getList',
  GET_DICTIONARY: 'grantOpportunities.getDictionary',
  TOGGLE_FAVORITE: 'grantOpportunities.toggleFavorite',
};

export const CONTRACT_OPPORTUNITIES = {
  EXPORT_CSV: 'contractOpportunities.exportCSV',
  ADD_NOT_INTERESTED: 'listOfNotInterested.create',
  GET_LIST: 'contractOpportunities.getList',
  GET_CONTRACT_BY_NAICS: 'contractOpportunities.getContractsByNaics',
  GET_POINT_OF_CONTACTS: 'contractOpportunities.getPointOfContacts',
  GET_ALL_NAICS: 'contractOpportunities.getAllNaicsByOpportunity',
  CREATE_QUOTE_REQUEST: 'quoteRequests.createQuoteRequest',
  TOGGLE_FAVORITE: 'contractOpportunities.toggleFavorite',
};

export const STATE_LOCAL_CONTRACT_OPPORTUNITIES = {
  EXPORT_CSV: 'stateLocalContractOpportunities.exportCSV',
  ADD_NOT_INTERESTED: 'listOfNotInterested.create',
  GET_LIST: 'stateLocalContractOpportunities.getList',
  GET_CONTRACT_BY_NAICS: 'stateLocalContractOpportunities.getContractsByNaics',
  GET_POINT_OF_CONTACTS: 'stateLocalContractOpportunities.getPointOfContacts',
  GET_ALL_NAICS: 'stateLocalContractOpportunities.getAllNaicsByOpportunity',
  CREATE_QUOTE_REQUEST: 'quoteRequests.createQuoteRequest',
  GET_PROJECT_DOCUMENTS: 'stateLocalContractOpportunities.getProjectDocuments',
  GET_DOWNLOADS: 'stateLocalContractOpportunities.getDownloads',
  GET_ATTACHMENTS_DOWNLOAD_URL: 'stateLocalContractOpportunities.getAttachmentsDownloadUrl',
  GET_ADDENDA_AND_NOTICES: 'stateLocalContractOpportunities.getAddendaAndNotices',
  TOGGLE_FAVORITE: 'stateLocalContractOpportunities.toggleFavorite',
};

export const CONTRACT_AWARDS = {
  EXPORT_CSV: 'contractAwards.exportCSV',
  ADD_NOT_INTERESTED: 'listOfNotInterested.create',
  GET_LIST: 'contractAwards.getList',
  FIND_ONE: 'contractAwards.findOne',
  FETCH_SUBAWARDS: 'contractAwards.getSubawards',
  EXPORT_SUBAWARDS_TO_CSV: 'contractAwards.exportSubawardsCsv',
  GET_RELATED_FCO: 'contractAwards.details.getContractOpportunitiesList',
  GET_TRANSACTIONS: 'contractAwards.details.getTransactions',
  EXPORT_TRANSACTIONS_CSV: 'contractAwards.details.exportTransactionsCsv',
  EXPORT_FEDERAL_CONTRACT_OPPORTUNITIES: 'contractAwards.details.exportFederalContractOpportunities',
  GET_SUBAWARDS_COUNT: 'contractAwards.getSubawardsCount',
  GET_SUBAWARDS_TOTALS: 'contractAwards.getSubawardsTotals',
  GET_FEDERAL_ACCOUNT_FUNDING_COUNT: 'contractAwards.getFederalAccountFundingCount',
  GET_FEDERAL_ACCOUNT_FUNDING: 'contractAwards.getFederalAccountFunding',
  EXPORT_FEDERAL_ACCOUNT_FUNDING_TO_CSV: 'contractAwards.exportFederalAccountFundingCsv',
};

export const FEDERAL_AGENTS = {
  EXPORT_CSV: 'federalAgents.exportCSV',
  ADD_NOT_INTERESTED: 'federalAgents.addToNotInterested',
  GET_LIST: 'federalAgents.getList',
  GET_LIST_AUTOCOMPLETE: 'federalAgents.getListAutocomplete',
  FIND_ONE: 'federalAgents.findOne',
  TOGGLE_FAVORITE: 'federalAgents.toggleFavorite',
  GET_NAICS_CODES: 'federalAgents.getNaicsCodes',
  GET_COLLEAGUES: 'federalAgents.getColleagues',
  GET_POSTED_OPPORTUNITIES: 'federalAgents.getPostedOpportunities',
  TOGGLE_USER_OPTION: 'federalAgents.toggleUserOption',
  GET_STATES: 'federalAgents.getStates',
};

export const CODE_CATEGORIES = {
  EXPORT_CSV: 'codeCategories.exportCSV',
  GET_LIST: 'codeCategories.getList',
  GET_LIST_NAICS_AUTOCOMPLETE: 'codeCategories.filterList.getNaicsListAutocomplete',
  GET_LIST_PSC_AUTOCOMPLETE: 'codeCategories.filterList.getPscListAutocomplete',
  TOGGLE_FAVORITE: 'codeCategories.toggleFavorite',
  FIND_ONE: 'codeCategories.details.findOne',
  FETCH_POPULAR_OPPORTUNITIES: 'codeCategories.details.getPopularOpportunities',
  GET_FUNDING: 'codeCategories.details.reports.getFunding',
  GET_AWARDS_COUNT: 'codeCategories.details.reports.getAwardsCount',
  FETCH_FEDERAL_CONTRACT_AWARDS: 'codeCategories.details.getFederalContractAwards',
  CONTRACT_OPPORTUNITIES: 'codeCategories.details.getContractOpportunities',
  CONTRACT_OPPORTUNITIES_EXPORT_CSV: 'codeCategories.details.contractOpportunitiesExportCSV',
};

export const FEDERAL_AGENCIES = {
  EXPORT_CSV: 'federalAgencies.exportCSV',
  GET_LIST: 'federalAgencies.getList',
  GET_LIST_AUTOCOMPLETE: 'federalAgencies.getListAutocomplete',
  FIND_ONE: 'federalAgencies.findOne',
  FETCH_HIERARCHY: 'federalAgencies.details.getHierarchy',
  FETCH_FEDERAL_CONTRACT_AWARDS: 'federalAgencies.details.getFederalContractAwards',
  EXPORT_FEDERAL_CONTRACT_AWARDS: 'federalAgencies.details.exportFederalContractAwards',
  FETCH_FEDERAL_PEOPLE: 'federalAgencies.details.getFederalPeople',
  EXPORT_FEDERAL_PEOPLE: 'federalAgencies.details.exportFederalPeopleCsv',
  GET_CONTRACT_OPPORTUNITIES: 'federalAgencies.details.getContractOpportunities',
  CONTRACT_OPPORTUNITIES_EXPORT_CSV: 'federalAgencies.details.contractOpportunitiesExportCSV',
  FETCH_FEDERAL_CONTRACT_IDVS: 'federalAgencies.details.getFederalContractIdvs',
  EXPORT_FEDERAL_CONTRACT_IDVS: 'federalAgencies.details.exportFederalContractIdvs',
  FETCH_GOVERMENT_FILES: 'federalAgencies.details.getGovermentFiles',
  EXPORT_GOVERMENT_FILES: 'federalAgencies.details.exportGovermentFiles',
  GET_FUNDING: 'federalAgencies.details.reports.getFunding',
  GET_AWARDS_COUNT: 'federalAgencies.details.reports.getAwardsCount',
  FETCH_POPULAR_OPPORTUNITIES: 'federalAgencies.details.getPopularOpportunities',
  FETCH_GRANT_OPPORTUNITIES: 'federalAgencies.details.getFederalGrantOpportunities',
  EXPORT_GRANT_OPPORTUNITIES: 'federalAgencies.details.exportFederalGrantOpportunities',
  FETCH_FUNDING_ANALYSIS: 'federalAgencies.details.fundingAnalysis',
  EXPORT_FUNDING_ANALYSIS: 'federalAgencies.details.exportFundingAnalysis',
};

export const GOVERMENT_FILES = {
  FIND_ONE: 'govermentFiles.findOne',
  EXPORT_CSV: 'govermentFiles.exportCSV',
  GET_LIST: 'govermentFiles.getList',
};

export const VENDORS = {
  GET_LIST: 'vendors.getList',
  EXPORT_CSV: 'vendors.exportCsv',
  FIND_ONE: 'vendors.findOne',
  TOGGLE_FAVORITE: 'vendors.toggleFavorite',
  GET_LOCATION_LIST: 'vendors.lists.getLocationList',
  TOGGLE_VENDOR_OPTION: 'vendors.vendorOptions',
  VENDOR_NOTE_OPTION: 'vendors.vendorNotes',
  GET_ALL_NAICS: 'vendors.getNaicsByVendor',
  GET_VENDORS_STRUCTURE: 'vendors.getStructureList',
  GET_VENDOR_TEAMING: 'vendors.getOrgTeaming',
  GET_FUNDING: 'vendors.details.reports.getFunding',
  GET_AWARDS_COUNT: 'vendors.details.reports.getAwardsCount',
  FETCH_FEDERAL_CONTRACT_AWARDS: 'vendors.details.getFederalContractAwards',
  FETCH_FUNDING_ANALYSIS: 'vendors.details.fundingAnalysis',
  GET_VENDORS_ASIDE: 'vendors.getSetAsideList',
  GET_ORG_AVATAR_BY_UEI: 'vendors.getOrgAvatarByUEI',
  GET_LOCAL_VENDOR: 'vendors.getOrgDetails',
  GET_ALL_VENDOR_NOTE_MEMBERS: 'vendors.getNoteMembers',
  ADD_VENDOR_NOTE_MEMBER: 'vendors.addNoteMember',
  REMOVE_VENDOR_NOTE_MEMBER: 'vendors.deleteNoteMember',
  UPDATE_VENDOR_NOTE_MEMBER: 'vendors.updateNoteMember',
  GET_ALL_VENDOR_NOTE: 'vendors.getOrgNotes',
  ADD_VENDOR_NOTE: 'vendors.addOrgNotes',
  REMOVE_VENDOR_NOTE: 'vendors.deleteOrgNotes',
  UPDATE_VENDOR_NOTE: 'vendors.updateOrgNotes',
  GET_ALL_VENDOR_NOTE_NAICS: 'vendors.getNoteNaics',
  ADD_VENDOR_NOTE_NAICS: 'vendors.addNoteNaics',
  REMOVE_VENDOR_NOTE_NAICS: 'vendors.deleteNoteNaics',
  UPDATE_VENDOR_NOTE_NAICS: 'vendors.updateNoteNaics',
};

export const VENDORS_MANAGEMENT = {
  GET_LIST: 'vendorsManagement.getList',
  FIND_ONE: 'vendorsManagement.findOne',
  ARCHIVE_SELECTED: 'vendorsManagement.archiveSelected',
  CHECK_VENDOR: 'vendorsManagement.checkVendor',
  ADD_VENDOR: 'vendorsManagement.addVendor',
  ADD_VENDOR_TAGS: 'vendorsManagement.addVendorTags',
  GET_VENDOR_TAGS: 'vendorsManagement.getVendorTags',
  GET_VENDORS_USER_COLUMNS: 'vendorsManagement.getVendorsUserColumns',
  UPDATE_VENDORS_USER_COLUMNS: 'vendorsManagement.updateVendorsUserColumns',
  GET_LOCATIONS_AUTOCOMPLETE: 'vendorsManagement.getLocationsAutocomplete',
  GET_DASHBOARD: 'vendorsManagement.getDashboard',
  CHECK_COLUMN_PREFERENCE: 'vendorsManagement.checkColumnPreferences',
  IMPORT_CSV: 'vendorsManagement.importCsv',
  ADD_VENDORS_FROM_IMPORT: 'vendorsManagement.addVendorsToImport',
  DELETE_SELECTED: 'vendorsManagement.deleteSelected',
};

export const FEATURED_VENDORS = {
  CREATE: 'featuredVendor.create',
  GET_LIST: 'featuredVendor.getList',
  RANDOM: 'featuredVendor.random',
  FIND_USER_CLAIM: 'featuredVendor.findUserClaim',
};
export const NOTIFICATIONS = {
  GET_UNREAD: 'notifications.getUnread',
  GET_LIST: 'notifications.getList',
  // FIND_ONE: 'notifications.findOne',
  REMOVE: 'notifications.deleteByIds',
  MARK_AS_TRASHED: 'notifications.markAsTrashed',
  MARK_AS_RESTORED: 'notifications.markAsRestored',
  MARK_AS_READ: 'notifications.markAsRead',
  GET_SETTINGS: 'notifications.getSettings',
  SET_SETTINGS: 'notifications.setSettings',
  MARK_ALL_TRASH: 'notifications.markAllTrash',
  RESTORE_ALL: 'notifications.restoreAll',
  DELETE_ALL: 'notifications.deleteAll',
  MARK_AS_READ_SINGLE: 'notifications.markAsReadSingle',
  GET_FGO_DETAILS: 'notifications.details.grantOpportunities',
  GET_FCO_DETAILS: 'notifications.details.contractOpportunities',
  GET_CA_DETAILS: 'notifications.details.contractAwards',
  GET_TEAMING_DETAILS: 'notifications.details.teaming',
  GET_TASKS_DETAILS: 'notifications.details.tasks',
  GET_ORG_DETAILS: 'notifications.details.organizations',
  GET_SUMMARY_COUNT: 'notifications.getSummaryCounts',
};

export const BANNER = {
  GET_BANNER: 'banner.getPublishedBanner',
};

export const NOT_INTERESTING = {
  GET_LIST: 'listOfNotInterested.getNotInterested',
  DELETE: 'listOfNotInterested.removeFromNotInterested',
};

export const PIPELINES = {
  GET_LIST: 'pipelines.getList',
  ADD_PIPELINE: 'pipelines.storePipeline',
  EDIT_PIPELINE: 'pipelines.editPipeline',
  DELETE_PIPELINE: 'pipelines.deletePipeline',
  GET_PIPELINE: 'pipelines.getPipeline',
  ADD_PURSUIT: 'pursuits.addToPipeline',
  DELETE_PIPELINE_STAGE: 'pipelines.deletePipelineStage',
  EDIT_PIPELINE_STAGE: 'pipelines.editPipelineStage',
  ADD_PIPELINE_STAGE: 'pipelines.addPipelineStage',
  GET_PURSUITS: 'pipelines.getPursuits',
  TOGGLE_INCLUDE_IN_STATISTICS: 'pipelines.toggleIncludeInStatistics',
  EXPORT_PURSUITS: 'pipelines.exportPursuits',
  GET_STATISTICS: 'pipelines.getStatistics',
};

export const NSNOPPORTUNITIES = {
  GET_LIST: 'nsnOpportunities.getList',
  EXPORT_CSV: 'nsnOpportunities.exportCsv',
  TOGGLE_FAVORITE: 'nsnOpportunities.toggleFavorite',
};

export const TAGS_USER = {
  GET_TAGS_BY_USER: 'tagsByUser.getTagsByUser',
};

export const PURSUITS = {
  UPDATE_PURSUITS_ORDER: 'pursuits.updatePursuitsOrder',
  EDIT_PURSUIT: 'pursuits.editPursuit',
  DELETE_PURSUIT: 'pursuits.deletePursuit',
  CHANGE_PURSUIT_STATUS: 'pursuits.changePursuitStatus',
  GET_PURSUITS: 'pursuits.getUserPursuits',
  ADD_PURSUIT: 'pursuits.storePursuit',
  LINK_PURSUIT: 'pursuits.linkOpportunityToPursuit',
  GET_PURSUIT_BY_ID: 'pursuits.findOne',
  STORE_TASK_ON_PURSUIT: 'tasks.create',
  UPDATE_TASK_ON_PURSUIT: 'tasks.update',
  DELETE_TASK_BY_ID: 'tasks.delete',
  CHANGE_STATUS_TASK_BY_ID: 'tasks.changeStatus',
  GET_TASKS_BY_PURSUIT_ID: 'pursuits.getTasksByPursuit',
  GET_DISCUSSIONS: 'pursuits.getDiscussionsByPursuit',
  GET_COMMENTS_BY_DISCUSSION: 'discussions.getComments',
  STORE_DISCUSSION: 'discussions.create',
  STORE_COMMENT_DISCUSSION: 'discussions.createComment',
  GET_USER_FILES: 'pursuits.getFilesByPursuit',
  GET_GOVERNMENT_FILES: 'pursuits.getGovernmentFilesByPursuit',
  UPLOAD_FILES: 'pursuits.uploadFilesByPursuit',
  EDIT_FILE: 'files.editFile',
  DELETE_FILE: 'files.deleteFile',
  UPLOAD_FILE_VERSION: 'pursuits.uploadFileVersion',
  GET_URL_UPLOAD_FILE: 'pursuits.getUrlUploadFile',
  GET_OPPORTUNITIES_BY_PURSUIT_ID: 'pursuits.getOpportunitiesByPursuit',
  GET_RELATED_BY_PURSUIT_ID: 'pursuits.getRelatedByPursuit',
  GET_LIKELY_BIDDERS_BY_PURSUIT_ID: 'pursuits.getLikelyBiddersByPursuit',
  GET_FULL_LOG_BY_PURSUIT: 'pursuits.getLog',
  GET_MEMBERS_BY_PURSUIT_ID: 'pursuits.getMembersByPursuit',
  ADD_MEMBER_TO_PURSUIT: 'pursuits.addMember',
  EDIT_MEMBER_PURSUIT: 'pursuits.editMember',
  DELETE_MEMBER_PURSUIT: 'pursuits.deleteMember',
  ENABLE_DISABLE_MEMBER_PURSUIT: 'pursuits.enableDisableMember',
};

export const TASKS = {
  GET_LIST: 'tasks.getList',
};

export const PERSONAL_TASKS = {
  GET_LIST: 'personalTasks.getList',
  CREATE_PERSONAL_TASK: 'personalTasks.create',
  UPDATE_PERSONAL_TASK: 'personalTasks.update',
  DELETE_PERSONAL_TASK: 'personalTasks.delete',
  CHANGE_STATUS_PERSONAL_TASK: 'personalTasks.changeStatus',
};

export const USERS = {
  GET_ORGANIZATION_USERS: 'user.usersByOrganization',
  GET_ORGANIZATIONS: 'user.getOrganizations',
};

export const ORGANIZATION = {
  UPDATE_SUBSCRIPTION_STATUS_TEST_ONLY: 'organization.updateSubscriptionStatusTestOnly',
  SUBSCRIPTION_STATUS: 'organization.subscriptionStatus',
  SET_PUBLIC_PROFILE: 'organization.setPublicProfile',
  GET_PUBLIC_PROFILE: 'organization.getPublicProfile',
  TOGGLE_PUBLIC_PROFILE: 'organization.togglePublicProfile',
  CHECK_ORGANIZATION: 'organization.checkOrganization',
  REGISTER_ORG: 'organization.registerOrg',
  SET_SEATS: 'billing.addLicensesToSubscription',
  SET_REVENUE_GOAL: 'organization.setRevenueGoal',
  GET_REVENUE_GOAL: 'organization.getRevenueGoal',
  DISMISS_UPGRADING_SUBSCRIPTION: 'organization.dismissUpgradeSubscription',
  GET_PERMISSIONS: 'organization.getPermissions',
  REGISTER_ORGANIZATION_MANUALLY: 'organization.registerOrganizationManually',
  GET_TEAMING_FILES: 'organization.getTeamingFiles',
  GET_ORGANIZATION_AVATAR: 'organization.getOrganizationAvatar',
};

export const UTILITIES = {
  GET_STATES: 'utilities.states',
  GET_COUNTRIES: 'utilities.countries',
  GET_STATES_BY_COUNTRY: 'utilities.statesByCountry',
  GET_CITIES_BY_STATE: 'utilities.citiesByState',
  GET_VENDORS_STRUCTURE: 'utilities.vendorsStructure',
};

export const TEAMING_OPPORTUNITIES = {
  GET_DASHBOARD: 'teamingOpportunities.getDashboard',
  GET_LIST: 'teamingOpportunities.getList',
  FIND_ONE: 'teamingOpportunities.findOne',
  FIND_ONE_COLLABORATOR: 'teamingOpportunities.findOneCollaborator',
  MARK_INTERESTED: 'teamingOpportunities.markInterested',
  MARK_SAVED: 'teamingOpportunities.saveTeamingOpportunity',
  GET_NAICS: 'teamingOpportunities.getNaics',
  GET_USER_PURSUITS: 'teamingOpportunities.getPursuits',
  ADD_OPPORTUNITY: 'teamingOpportunities.addOpportunity',
  ADD_OPPORTUNITY_REQUEST: 'teamingOpportunities.addOpportunityRequest',
  CLOSE_OPPORTUNITY: 'teamingOpportunities.closeOpportunity',
  GET_INTERESTED_USERS: 'teamingOpportunities.getInterestedUsers',
  CHANGE_STATUS_COLLABORATOR: 'teamingOpportunities.changeStatusCollaborator',
  DELETE_REQUEST_COLLABORATOR: 'teamingOpportunities.deleteRequestCollaborator',
  GET_RECOMMENDED_CONTRACTORS: 'teamingOpportunities.getRecommendedContractors',
  FIND_INTERESTED_CONTRACTOR: 'teamingOpportunities.findInterestedContractor',
};

export const BUSINESS = {
  GET_OVERVIEW: 'business.overview.getInformation',
  GET_TICKETS: 'business.tickets.getList',
  ADD_TICKET: 'business.tickets.create',
  EDIT_TICKET: 'business.tickets.update',
  DELETE_TICKET: 'business.tickets.delete',
  CLOSE_TICKET: 'business.tickets.close',
  GET_DOCUMENTS: 'business.documents.getList',
  GET_FILES_IN_FOLDER: 'business.documents.getFilesInFolder',
  DOWNLOAD_FILE_DRIVE: 'business.documents.downloadFile',
  GET_RESPONSES_BY_TICKETS: 'business.tickets.getResponsesByTickets',
  GET_TICKET_CATEGORIES: 'business.tickets.getCategories',
  BACKUP_FILE_REQ_VERSION: 'business.documents.backupToFileReqVersion',
};

export const CLIENT_PORTAL = {
  GET_FORMSTACK_BY_ID: 'formStack.getFormById',
  FORM_PARTIAL_SUBMISSION: 'formStack.formPartialSubmission',
  FORM_SUBMISSION: 'formStack.formSubmission',
  GET_ORGANIZATION_FORMS: 'formStack.getOrganizationForms',
};

export const RELEASES = {
  GET_RELEASES: 'releases.getReleases',
};

export const QUOTE_REQUESTS = {
  GET: 'quoteRequests.getList',
  PAYMENT: 'billing.createChargeQuoteRequest',
};

export const BILLING = {
  GET_CUSTOMER_BILLING: 'billing.getCustomer',
  ADD_PAYMENT_METHOD: 'billing.attachPaymentMethodToCustomer',
  UPDATE_BILLING: 'billing.updatePaymentMethod',
  REMOVE_PAYMENT_METHOD: 'billing.detachPaymentMethod',
  GET_PLANS: 'billing.getPlans',
  GET_INVOICES: 'billing.getInvoices',
  CREATE_SUBSCRIPTION: 'billing.createSubscription',
  CANCEL_SUBSCRIPTION: 'billing.cancelSubscription',
  GET_INVOICE_BY_ID: 'billing.getInvoiceById',
  GET_SUBSCRIPTION_ID: 'billing.getSubscriptionInfo',
  APPLY_PROMO_CODE: 'billing.applyPromoCode',
  SHARE_INVOICE: 'billing.shareInvoice',
  UPGRADE_DOWNGRADE_SUBSCRIPTION: 'billing.upgradeDowngradeSubscription',
  UPDATE_SUBSCRIPTION: 'billing.updateSubscription',
  GET_SEAT_PRICE: 'billing.getSeatPrice',
  GET_PRODUCTS: 'billing.getProducts',
  GET_ZIP_CODES: 'billing.getZipCodesList',
};

export const EXPORTS = {
  GET_LIST: 'exports.getList',
  GET_STATS: 'exports.getStats',
};

export const SUGGESTED_SEARCHES = {
  GET_LIST: 'suggestedSearch.getList',
  FIND_ONE: 'suggestedSearch.findOne',
  ADD_TO_SAVED: 'suggestedSearch.addToSaved',
  ADD_ALL_TO_LIST: 'suggestedSearch.addAllToList',
  ADD_TO_NOT_INTERESTED: 'suggestedSearch.addToNotInterested',
};

export const CALENDAR = {
  SYNC_CALENDAR: 'calendar.syncCalendar',
  SYNC_CALENDAR_REMINDER: 'calendar.enableSyncCalendarReminder',
  GET_USERS_EVENTS: 'calendar.getUsersEvents',
  GET_USERS_TO_INVITE: 'calendar.getUsersToInvite',
  GET_USERS_WHO_SHARED: 'calendar.getUsersWhoShared',
  GET_USERS_SHARED_TO: 'calendar.getUsersSharedTo',
  SHARE_CALENDAR: 'calendar.shareCalendar',
  DELETE_FROM_SHARED_TO: 'calendar.deleteFromSharedTo',
  DELETE_FROM_WHO_SHARED: 'calendar.deleteFromWhoShared',
};

export const ONBOARDING_MEETING = {
  GET_CALENDLY_URL: 'onboardingMeeting.getCalendlyUrl',
  GET_SCHEDULED_MEETINGS: 'onboardingMeeting.getScheduledMeetings',
  SAVE_MEETING_INVITATION: 'onboardingMeeting.saveMeetingInvitation',
};
